body {
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    scroll-margin-top: 200px;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: black;
}

.dangerous-html img {
    /* max-width: inherit; */
    max-width: 100%;
    width: revert-layer;
}

.dangerous-html.sun-editor-editable {
    font-family: inherit;
    font-size: inherit;
    max-width: 100%;
}

.dangerous-html.sun-editor-editable .se-image-container a img {
    outline: none !important;
    margin: 0;
    padding: 0;
}

.dangerous-html.sun-editor-editable.light {
    color: black;
    background-color: white;
}

.dangerous-html.sun-editor-editable.dark {
    color: white;
    background-color: #1c1c1e;
}
.sun-editor-editable {
    font-family: inherit;
    font-size: inherit;
}

.dangerous-html.sun-editor-editable h2 {
    font-size: x-large;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
}

@media (min-width: 768px) {
    .dangerous-html.sun-editor-editable h2 {
        font-size: xx-large;
    }
}

.dangerous-html.sun-editor-editable h3 {
    font-size: larger;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
}

.dangerous-html.sun-editor-editable figure figcaption {
    background-color: #02020212;
    padding: 10px 15px;
}
.dangerous-html.sun-editor-editable.dark figure figcaption {
    background-color: #acacac1c;
}

@media (min-width: 768px) {
    .dangerous-html.sun-editor-editable h3 {
        font-size: x-large;
    }
}

@keyframes float {
    0% {
        /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
        transform: translatey(0px);
    }
    50% {
        /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
        transform: translatey(-20px);
    }
    100% {
        /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
        transform: translatey(0px);
    }
}

/* TIMELINE */
.main-timeline {
    position: relative;
}
.main-timeline:before,
.main-timeline:after {
    content: '';
    display: block;
    width: 100%;
    clear: both;
}
.main-timeline:before {
    content: '';
    width: 3px;
    height: 100%;
    background: #328371;
    position: absolute;
    top: 0;
    left: 50%;
}
.main-timeline .timeline {
    width: 50%;
    float: left;
    position: relative;
    z-index: 1;
    opacity: 0;
}
.main-timeline .timeline:nth-child(even) {
    animation: slideleft 1s ease forwards;
}
.main-timeline .timeline:nth-child(odd) {
    animation: slideright 1s ease forwards;
}
.main-timeline .timeline:nth-child(1) {
    animation-delay: 0s;
}
.main-timeline .timeline:nth-child(2) {
    animation-delay: 1s;
    animation-duration: 0.9s;
}
.main-timeline .timeline:nth-child(3) {
    animation-delay: 2s;
    animation-duration: 0.8s;
}
.main-timeline .timeline:nth-child(4) {
    animation-delay: 3s;
    animation-duration: 0.7s;
}
.main-timeline .timeline:nth-child(5) {
    animation-delay: 4s;
    animation-duration: 0.6s;
}
.main-timeline .timeline:nth-child(6) {
    animation-delay: 5s;
    animation-duration: 0.5s;
}
.main-timeline .timeline:nth-child(7) {
    animation-delay: 6s;
    animation-duration: 0.4s;
}
.main-timeline .timeline:nth-child(8) {
    animation-delay: 7s;
    animation-duration: 0.3s;
}
.main-timeline .timeline:before,
.main-timeline .timeline:after {
    content: '';
    display: block;
    width: 100%;
    clear: both;
}
.main-timeline .timeline:last-child:before {
    top: auto;
    bottom: 0;
}
.main-timeline .timeline:last-child:nth-child(even):before {
    right: auto;
    left: -12px;
    bottom: -2px;
}
.main-timeline .timeline-content {
    text-align: center;
    margin-top: 8px;
    position: relative;
    transition: all 0.3s ease 0s;
}
.main-timeline .timeline-content:before {
    content: '';
    width: 100%;
    height: 5px;
    background: #328371;
    position: absolute;
    top: 88px;
    left: 0;
    z-index: -1;
}
.main-timeline .circle {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: #fff;
    border: 8px solid #328371;
    float: left;
    margin-right: 25px;
    position: relative;
}
.main-timeline .circle span {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    line-height: 268px;
    font-size: 80px;
    color: #454344;
}
.main-timeline .circle span:after {
    border-radius: 21px 0 0 0;
    top: 0;
    bottom: -56px;
}
.main-timeline .circle .img {
    vertical-align: initial;
    border-radius: 50%;
    transform: scale(1.01);
}
.main-timeline .content {
    display: table;
    padding-right: 40px;
    position: relative;
}
.main-timeline .year {
    display: block;
    padding: 10px;
    margin: 10px 0 50px 0;
    background: #8cccbe;
    color: #4c4c4c;
    border-radius: 7px;
    font-size: 25px;
}
.main-timeline .title {
    font-size: 25px;
    font-weight: bold;
    color: #4c4c4c;
    margin-top: 0;
}
.main-timeline .description {
    font-size: 14px;
    color: #4c4c4c;
    text-align: justify;
}
.main-timeline .icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #8cccbe; /* fff */
    border: 4px solid #328371;
    position: absolute;
    top: 78px;
    right: -14px;
}
.main-timeline .icon span:after {
    border-radius: 0 21px 0 0;
    top: 0;
    left: -15px;
    bottom: -30px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .circle {
    float: right;
}
.main-timeline .timeline:nth-child(2n) .circle {
    margin: 0 0 0 25px;
}
.main-timeline .timeline:nth-child(2n) .circle:before {
    right: auto;
    left: -33px;
    box-shadow: -7px 0 9px -7px #444 inset;
}
.main-timeline .timeline:nth-child(2n) .circle span:before,
.main-timeline .timeline:nth-child(2n) .circle span:after {
    right: auto;
    left: -33px;
    border-radius: 0 0 21px 0;
}
.main-timeline .timeline:nth-child(2n) .circle span:after {
    border-radius: 0 21px 0 0;
}
.main-timeline .timeline:nth-child(2n) .content {
    padding: 0 0 0 40px;
    margin-left: 2px;
}
.main-timeline .timeline:nth-child(2n) .icon {
    right: auto;
    left: -14px;
}
.main-timeline .timeline:nth-child(2n) .icon:before,
.main-timeline .timeline:nth-child(2n) .icon span:before,
.main-timeline .timeline:nth-child(2n) .icon span:after {
    left: auto;
    right: -15px;
}
.main-timeline .timeline:nth-child(2n) .icon span:before {
    border-radius: 0 0 0 21px;
}
.main-timeline .timeline:nth-child(2n) .icon span:after {
    border-radius: 21px 0 0 0;
}
.main-timeline .timeline:nth-child(2) {
    margin-top: 180px;
}
.main-timeline .timeline:nth-child(odd) {
    margin: -175px 0 0 0;
}
.main-timeline .timeline:nth-child(even) {
    margin-bottom: 180px;
}
.main-timeline .timeline:first-child,
.main-timeline .timeline:last-child:nth-child(even) {
    margin: 0;
}
.homebox img {
    width: 164px;
}
@media only screen and (max-width: 990px) {
    .main-timeline .timeline:nth-child(even) {
        animation: none;
    }
    .main-timeline .timeline:nth-child(odd) {
        animation: none;
    }
    .main-timeline:before {
        left: 100%;
    }
    .main-timeline .timeline {
        opacity: 1;
        width: 100%;
        float: none;
        margin-bottom: 20px !important;
    }
    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before {
        left: auto !important;
        right: -13px !important;
    }
    .main-timeline .timeline:nth-child(2n) .circle {
        float: left;
        margin: 0 25px 0 0;
    }
    .main-timeline .timeline:nth-child(2n) .circle:before {
        right: -33px;
        left: auto;
        box-shadow: 7px 0 9px -7px #444 inset;
    }
    .main-timeline .timeline:nth-child(2n) .circle span:before,
    .main-timeline .timeline:nth-child(2n) .circle span:after {
        right: -33px;
        left: auto;
        border-radius: 0 0 0 21px;
    }
    .main-timeline .timeline:nth-child(2n) .circle span:after {
        border-radius: 21px 0 0 0;
    }
    .main-timeline .timeline:nth-child(2n) .content {
        padding: 0 40px 0 0;
        margin-left: 0;
    }
    .main-timeline .timeline:nth-child(2n) .icon {
        right: -14px;
        left: auto;
    }
    .main-timeline .timeline:nth-child(2n) .icon:before,
    .main-timeline .timeline:nth-child(2n) .icon span:before,
    .main-timeline .timeline:nth-child(2n) .icon span:after {
        left: -15px;
        right: auto;
    }
    .main-timeline .timeline:nth-child(2n) .icon span:before {
        border-radius: 0 0 21px 0;
    }
    .main-timeline .timeline:nth-child(2n) .icon span:after {
        border-radius: 0 21px 0 0;
    }
    .main-timeline .timeline:nth-child(2),
    .main-timeline .timeline:nth-child(odd),
    .main-timeline .timeline:nth-child(even) {
        margin: 0;
    }
    .main-timeline .circle:before,
    .main-timeline .circle span:before,
    .main-timeline .circle span:after,
    .main-timeline .icon {
        display: none;
    }
}
@media only screen and (max-width: 480px) {
    .main-timeline .timeline:nth-child(even) {
        animation: none;
    }
    .main-timeline .timeline:nth-child(odd) {
        animation: none;
    }
    .main-timeline .timeline {
        opacity: 1;
    }
    .main-timeline:before {
        left: 0;
    }
    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before {
        left: -12px !important;
        right: auto !important;
    }
    .main-timeline .circle,
    .main-timeline .timeline:nth-child(2n) .circle {
        width: 130px;
        height: 130px;
        float: none;
        margin: 0 auto;
    }
    .main-timeline .timeline-content:before {
        width: 99.5%;
        top: 68px;
        left: 0.5%;
    }
    .main-timeline .circle span {
        line-height: 115px;
        font-size: 60px;
    }
    .main-timeline .circle:before,
    .main-timeline .circle span:before,
    .main-timeline .circle span:after,
    .main-timeline .icon {
        display: none;
    }
    .main-timeline .content,
    .main-timeline .timeline:nth-child(2n) .content {
        padding: 0 10px;
    }
    .main-timeline .year {
        margin-bottom: 15px;
    }
    .main-timeline .description {
        text-align: center;
    }
    .homebox img {
        width: 114px;
    }
}

@keyframes slideleft {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideright {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
